import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IconCopyrightLine,
    IconEtmaASignSolid,
    IconEtmaESignSolid,
    IconEtmaMSignSolid,
    IconEtmaTSignSolid,
    IconTwitchUnsafeLine,
} from '@assets/images/svg';
import { EtmaRating, ISticker } from '@typings';

import { FlagItem } from './components';
import s from './Flags.scss';

interface IFlagsProps {
    sticker: ISticker;
}

export const Flags = (props: IFlagsProps) => {
    const { sticker } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.flags.${id}`);

    return (
        <div className={s.root}>
            {sticker.hasCopyright && (
                <FlagItem
                    icon={<IconCopyrightLine/>}
                    tooltip={t('copyright')}
                />
            )}
            {sticker.isNotTwitchSafe && (
                <FlagItem
                    icon={<IconTwitchUnsafeLine/>}
                    tooltip={t('notTwitchSafe')}
                />
            )}
            {sticker.etmaRating === EtmaRating.E && (
                <FlagItem
                    icon={<IconEtmaESignSolid/>}
                    tooltip={t('etmaRatingE')}
                />
            )}
            {sticker.etmaRating === EtmaRating.T && (
                <FlagItem
                    icon={<IconEtmaTSignSolid/>}
                    tooltip={t('etmaRatingT')}
                />
            )}
            {sticker.etmaRating === EtmaRating.M && (
                <FlagItem
                    icon={<IconEtmaMSignSolid/>}
                    tooltip={t('etmaRatingM')}
                />
            )}
            {sticker.etmaRating === EtmaRating.A && (
                <FlagItem
                    icon={<IconEtmaASignSolid/>}
                    tooltip={t('etmaRatingA')}
                />
            )}
        </div>
    );
};
