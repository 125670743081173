// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bottom__root--OaUe9 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 0 30px;
  height: 60px;
}

.Bottom__controls--yekiw {
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.Bottom__bottomLine--oMdoA {
  position: absolute;
  bottom: 25px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px 0 25px;
}
.Bottom__bottomLineLeft--KSpEw {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/base/views/modals/StickerPopup/components/Bottom/Bottom.scss","webpack://./src/base/views/modals/StickerPopup/StickerPopup.const.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,sBAAA;EACA,oBAAA;EACA,YCRY;ADOhB;;AAIA;EACI,kBAAA;EACA,cAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;AADJ;;AAIA;EACI,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;EACA,sBAAA;AADJ;AAGI;EACI,aAAA;AADR","sourcesContent":["@use '../../StickerPopup.const';\n\n.root {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    box-sizing: border-box;\n    padding: 18px 0 30px;\n    height: StickerPopup.$height-bottom;\n}\n\n.controls {\n    position: absolute;\n    margin: 0 auto;\n    left: 50%;\n    top: 20px;\n    transform: translateX(-50%);\n}\n\n.bottomLine {\n    position: absolute;\n    bottom: 25px;\n    box-sizing: border-box;\n    width: 100%;\n    padding: 0 20px 0 25px;\n\n    &Left {\n        display: flex;\n    }\n}\n","$height-top: 60px;\n$height-bottom: 60px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Bottom__root--OaUe9`,
	"controls": `Bottom__controls--yekiw`,
	"bottomLine": `Bottom__bottomLine--oMdoA`,
	"bottomLineLeft": `Bottom__bottomLineLeft--KSpEw`
};
export default ___CSS_LOADER_EXPORT___;
