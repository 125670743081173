import React, { PropsWithChildren } from 'react';

import { useApp } from '@hooks';
import { BasicPage } from '@memealerts/views';

export const BasicContent = (props: PropsWithChildren) => {
    const app = useApp();
    const { children } = props;

    return (
        <BasicPage {...app}>
            {children}
        </BasicPage>
    );
};
