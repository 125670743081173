import { Column, EComponentSize, ERowAlignment, ERowJustification, Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { Avatar } from '@components';
import { ISticker } from '@typings';

import s from './Top.scss';
import { IStickerPopupDataExtended } from '../../types';

interface ITopProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    closeModal(): void;
}

export const Top = (props: ITopProps) => {
    const {
        sticker,
        closeModal,
        onClose = noop,
    } = props;

    const handleCloseClick = () => {
        closeModal();
        onClose();
    };

    return (
        <Column className={s.root}>
            <Row
                className={s.topLine}
                justification={ERowJustification.SPACE_BETWEEN}
                alignment={ERowAlignment.CENTER}
            >
                <div className={s.topLineLeft}>
                    <div className={s.creator}>
                        <Avatar
                            className={s.creatorAvatar}
                            rounded={true}
                            isUserAvatar={true}
                            size={EComponentSize.SMALL}
                            source={sticker.creatorAvatar}
                        />
                        <div className={s.creatorName}>{sticker.creatorName}</div>
                    </div>
                </div>

                <div className={s.topLineRight}>
                    <div className={s.closeArea} onClick={handleCloseClick}>
                        <IconCloseLine className={s.close}/>
                    </div>
                </div>
            </Row>
        </Column>
    );
};
