import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconTrashAltLine } from '@assets/images/svg';
import { ISticker } from '@typings';

import { ControlItem } from '../ControlItem';
import { ControlPopupDelete } from '../ControlPopupDelete';


interface IControlDeleteProps {
    sticker: ISticker;
    isPopupDisplayed: boolean;
    onClick(): void;
    onClose(): void;
}

export const ControlDelete = (props: IControlDeleteProps) => {
    const {
        sticker,
        isPopupDisplayed,
        onClick,
        onClose,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.controls.${id}`);

    return (
        <ControlItem
            title={t('delete')}
            icon={<IconTrashAltLine/>}
            onClick={onClick}
        >
            {isPopupDisplayed && (
                <ControlPopupDelete
                    sticker={sticker}
                    onClose={onClose}
                />
            )}
        </ControlItem>
    );
};
