import React from 'react';
import { Outlet } from 'react-router-dom';

import { useApp } from '@hooks';
import { StreamerSettingsPage } from '@memealerts/views';

interface IStreamerSettingsProps {
    noRightSidebar?: boolean;
}

export const StreamerSettings = (props: IStreamerSettingsProps) => {
    const app = useApp();

    return (
        <StreamerSettingsPage {...app} {...props}>
            <Outlet context={app}/>
        </StreamerSettingsPage>
    );
};
