import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const DonationAlertsRedirect = () => {
    const [searchParams] = useSearchParams();

    const processStatus = () => {
        const status = searchParams.get('status');

        console.log(status);

        if (!status) {
            console.log('Error!');
        }

        return status;
    };

    useEffect(() => {
        const status = processStatus();
        window.opener.postMessage({ status });
    }, []);

    return (<></>);
};
