import {
    Icon,
    applyMargin,
    applyPadding,
    stub,
    EComponentSize,
    IBlockComponentProps,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties, forwardRef, Ref, useEffect, useState } from 'react';

import { ImageDefaultUser } from '@assets/images/png';
import { IconUploadLine } from '@assets/images/svg';

import s from './Avatar.scss';


interface IAvatarProps extends IBlockComponentProps {
    changeAllowed?: boolean;
    showUploadWhenEmpty?: boolean;
    rounded?: boolean;
    size?: EComponentSize;
    source?: string;
    withBorder?: boolean;
    inputRef?: Ref<HTMLInputElement>;
    fallbackImage?: string;
    isUserAvatar?: boolean;
    preventShowDefault?: boolean;
    onClick?: () => void;
    onChanged?: (file: File) => void;
}

// TODO: Implement state machine to take into account isError, source (present or not)

export const Avatar = forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<IAvatarProps>
>((props: IAvatarProps, ref) => {
    const {
        className,
        fallbackImage,
        height,
        margin,
        padding,
        size = EComponentSize.MEDIUM,
        source,
        changeAllowed,
        style,
        showUploadWhenEmpty,
        preventShowDefault,
        rounded,
        width,
        withBorder,
        inputRef,
        isUserAvatar,
        onClick,
        onChanged = stub,
    } = props;

    const [isError, setIsError] = useState(false);
    const noImage = showUploadWhenEmpty && (!source || isError);
    const avatarUrl = source
        ? isError
            ? fallbackImage ?? ImageDefaultUser
            : source
        : !preventShowDefault
            ? fallbackImage ?? ImageDefaultUser
            : undefined;
    const isSvg = !!avatarUrl && /\.svg$/.test(avatarUrl);

    const avatarClassnames = cn(
        s.avatar,
        s[`size${size}`],
        {
            [s.avatarRounded]: rounded,
            [s.avatarWithBorder]: withBorder,
            [s.avatarGradient]: isUserAvatar,
            [s.clickable]: onClick !== undefined,
        },
        className,
    );

    const avatarStyles: CSSProperties = {
        ...style,
        height,
        width,
    };

    applyMargin(avatarStyles, margin);
    applyPadding(avatarStyles, padding);

    const onAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) onChanged(e.target.files[0]);
    };

    const onImageLoadError = () => {
        setIsError(true);
    };

    useEffect(() => {
        if (source?.startsWith('data')) {
            setIsError(false);
        }
    }, [source]);

    useEffect(() => {
        if (avatarUrl) {
            const img = new Image();

            img.onerror = () => setIsError(true);
            img.src = avatarUrl;
        }
    }, [avatarUrl]);

    return (
        <div
            ref={ref}
            className={avatarClassnames}
            style={avatarStyles}
            onClick={onClick}
        >
            { noImage
                ? <Icon icon={<IconUploadLine/>} size={EComponentSize.MEDIUM} />
                : (isUserAvatar && source && !isError && isSvg)
                    ? (
                        <div
                            className={s.avatarPicture}
                            style={{ backgroundImage: `url("${avatarUrl}")` }}
                        />
                    )
                    : (
                        <img
                            alt=''
                            className={s.avatarImage}
                            src={avatarUrl}
                            onClick={onClick}
                            onError={onImageLoadError}
                        />
                    )
            }
            { changeAllowed &&
                <input
                    className={s.avatarSelectInput}
                    type={'file'}
                    ref={inputRef}
                    onChange={onAvatarChange}
                />
            }
        </div>
    );
});

Avatar.displayName = 'Avatar';
