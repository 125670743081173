import { useEffect, useState } from 'react';

import { CACHED_STATE_INVITE_ACTIVATED_NAME, CACHED_STATE_SHOW_INVITE_PROMPT_NAME } from '@common';
import { useApi, useAuth } from '@hooks';
import { IInvite } from '@typings';

export const useInvites = () => {
    const {
        invites: {
            useActivateInviteMutation,
            useInviteActivationStatusQuery,
            useInvitesListQuery,
        },
    } = useApi();

    const { authToken } = useAuth();

    const { data: inviteStatus, isFetching: isInviteStatusLoading } = useInviteActivationStatusQuery(void(0), { skip: !authToken });
    const { data: loadedInvites, refetch: reloadInvites } = useInvitesListQuery(void(0), { skip: !authToken });
    const [activate, { data: activationResult }] = useActivateInviteMutation();

    const [invites, setInvites] = useState<Array<IInvite>>([]);
    const [activationErrorMessage, setActivationErrorMessage] = useState('');
    const [inactiveInvitesCount, setInactiveInvitesCount] = useState(0);

    const activateInvite = async (invite: string) => {
        const result = await activate({ invite }).unwrap();

        if (result.activated) {
            reloadInvites();
        }
    };

    const clearActivationErrorMessage = () => setActivationErrorMessage('');

    useEffect(() => {
        if (activationResult && activationResult.error) {
            setActivationErrorMessage(activationResult.errorDetails ?? activationResult.errorMessage ?? 'Unknown error');
        }
    }, [activationResult]);

    useEffect(() => {
        if (loadedInvites) {
            setInvites(loadedInvites);
            setInactiveInvitesCount(loadedInvites.filter((invite) => !invite.activated).length);
        }
    }, [loadedInvites]);

    useEffect(() => {
        if (!isInviteStatusLoading) {
            const invitesEnabled = inviteStatus?.status ?? true;
            const inviteActivated = inviteStatus?.inviteActivated ?? false;
            localStorage.setItem(CACHED_STATE_SHOW_INVITE_PROMPT_NAME, (invitesEnabled && !inviteActivated).toString());
            localStorage.setItem(CACHED_STATE_INVITE_ACTIVATED_NAME, (!invitesEnabled || inviteActivated).toString());
        }
    }, [inviteStatus]);

    return {
        /** The flag signs that loading of invite status is in process */
        isInviteStatusLoading,

        /** Count of inactive (free) invites that can be provided to other users */
        inactiveInvitesCount,

        /** List of the user invites that can be provided to other users */
        invites,

        /** The flag shows that using of invites is enabled by administrators */
        invitesEnabled: inviteStatus?.status ?? true,

        /** The current user has activated invite */
        inviteActivated: inviteStatus?.inviteActivated,

        /** An error message that may have been set during invite activation */
        activationErrorMessage,

        /** Makes a request to activate an invitation */
        activateInvite,

        /** Clears activation error message */
        clearActivationErrorMessage,
    };
};
