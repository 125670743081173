import React from 'react';
import { RouteObject } from 'react-router-dom';

import { ImageMemeAlertsBanner } from '@assets/images/png';
import { mergeObjectArrays } from '@common';
import {
    PersonalArea,
    StreamerSettings,
    Privacy,
    Support,
    Terms,
    BasicContent,
    PaymentsSettings,
    StreamerArea, DonationAlertsRedirect,
} from '@memealerts/views';
import { routing as baseRouting } from '@routing';
import { AuthRequired, InviteRequired } from '@routing/guards';
import {
    ChannelRouting,
    DashboardRoutes,
    EventsRoutes,
    PersonalAreaRoutes,
    StreamerAreaRoutes,
    SupportersRoutes,
} from '@routing/routes';
import { CreateSticker, EventsPage, InvitePrompt, PageSettings, PersonalSettings, Suspension } from '@views';

export const memeAlertsRouting = mergeObjectArrays<RouteObject>(
    baseRouting,
    [
        {
            path: '/channel',
            element: <AuthRequired>
                <InviteRequired invitePrompt={
                    <BasicContent>
                        <InvitePrompt/>
                    </BasicContent>
                }>
                    <StreamerSettings noRightSidebar/>
                </InviteRequired>
            </AuthRequired>,
            children: [...mergeObjectArrays<RouteObject>(
                ChannelRouting,
                [
                    {
                        path: 'payments',
                        element: <PaymentsSettings/>,
                    },
                    {
                        path: 'page',
                        element: <PageSettings bannerToDownload={ImageMemeAlertsBanner}/>,
                    },
                ],
                'path',
            )],
        },
        {
            path: '/dashboard',
            element: <AuthRequired>
                <PersonalArea noRightSidebar/>
            </AuthRequired>,
            children: [...DashboardRoutes],
        },
        {
            path: '/events',
            element: <AuthRequired>
                <InviteRequired invitePrompt={
                    <BasicContent>
                        <InvitePrompt/>
                    </BasicContent>
                }>
                    <PersonalArea/>
                </InviteRequired>
            </AuthRequired>,
            children: [...EventsRoutes],
        },
        {
            path: '/sticker/create',
            element: <AuthRequired><BasicContent><CreateSticker/></BasicContent></AuthRequired>,
        },
        {
            path: '/stickers',
            element: <AuthRequired><PersonalArea/></AuthRequired>,
            children: [...PersonalAreaRoutes],
        },
        {
            path: '/supporters',
            element: <AuthRequired><PersonalArea/></AuthRequired>,
            children: [...SupportersRoutes],
        },
        {
            path: '/all-events',
            element: <AuthRequired allowUseObsToken><EventsPage/></AuthRequired>,
        },
        {
            path: 'terms',
            element: <Terms/>,
        },
        {
            path: 'support',
            element: <Support/>,
        },
        {
            path: 'privacy',
            element: <Privacy/>,
        },
        {
            path: '/settings',
            element: <AuthRequired><BasicContent><PersonalSettings/></BasicContent></AuthRequired>,
        },
        {
            path: '/:streamerChannelName',
            element: <StreamerArea/>,
            children: [...StreamerAreaRoutes],
        },
        {
            path: '/auth/donationalerts',
            element: <DonationAlertsRedirect />,
        },
        {
            path: '/suspended',
            element: <BasicContent><Suspension/></BasicContent>,
        },
    ],
    'path',
);
