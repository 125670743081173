import { ETooltipPosition, Tooltip } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

import s from './FlagItem.scss';

interface IFlagItemProps {
    className?: string;
    icon: ReactNode;
    tooltip: string;
}

export const FlagItem = (props: IFlagItemProps) => {
    const {
        className,
        icon,
        tooltip,
    } = props;

    return (
        <Tooltip
            content={tooltip}
            position={ETooltipPosition.TOP}
            classNames={{
                root: s.tooltip,
            }}
        >
            <div className={cn(s.flag, className)}>
                {icon}
            </div>
        </Tooltip>
    );
};
