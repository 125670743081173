import {
    Button,
    Column,
    EColumnJustification,
    EComponentColor,
    ITextFieldAction,
    ITextFieldMethods,
    Row,
    SearchField,
    useClickOutside,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { ReactElement, useRef, useState } from 'react';


import { IconArrowRightLine, IconBellLine, IconCloseLine, IconMagnifierLine, IconStar4Line } from '@assets/images/svg';
import { Avatar } from '@components';
import { TApp } from '@hooks';
import { BalanceButton, EarnBonusButton, Login, ProfileMenu, WelcomeBonusPopup } from '@views';

import s from './FloatingHeader.scss';

enum SearchTextFieldAction {
    ENTER = 'enter',
    RESET = 'reset',
}

interface FloatingHeaderProps extends TApp {
    logo?: ReactElement;
    visible?: boolean;
    onLogoClick?: () => void;
}

export const FloatingHeader = (props: FloatingHeaderProps) => {
    const {
        balance: {
            balanceLoaded,
            earnWelcomeBonus,
            reloadBalance,
            welcomeBonusEarned,
        },
        currentUser: {
            currentUser,
            logout,
        },
        deviceInfo: {
            isDesktopLayoutRecommended,
            isMobileLayoutRecommended,
        },
        navigation: {
            goToStickerCreation,
            goToStreamerPage,
            gotoStreamerPageSearchResult,
            openEventsTab,
        },
        streamer: {
            streamer,
        },
        settings: {
            system,
        },
        streamerArea: {
            stickers: {
                search: {
                    searchQuery,
                    changeSearchQuery,
                },
            },
        },
        logo,
        visible,
    } = props;

    const { showModal } = useModals();

    const avatarRef = useRef<HTMLDivElement>(null);
    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    const [enteredSearchQuery, setEnteredSearchQuery] = useState('');
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        switch (action.name) {
            case SearchTextFieldAction.RESET:
                changeSearchQuery('');
                goToStreamerPage(streamer?.channel.link);

                return true;
            case SearchTextFieldAction.ENTER:
                changeSearchQuery(enteredSearchQuery);
                gotoStreamerPageSearchResult(streamer?.channel.link, enteredSearchQuery);
                break;
        }
    };

    useClickOutside({
        controlledElementRef: avatarRef,
        handler: () => setIsMenuVisible(false),
    });

    const onEarnWelcomeBonusClick = async () => {
        if (!currentUser) {
            showModal({
                content: <Login {...props} returnUrl={location.pathname}/>,
                options: {
                    bodyClassName: s.loginModalBody,
                },
            });
        }

        if (currentUser && streamer) {
            const result = await earnWelcomeBonus(streamer.id).unwrap();

            if (result.welcomeBonusEarned) {
                reloadBalance();
                showModal(<WelcomeBonusPopup streamer={streamer} />);
            }
        }
    };

    const logoSidePadding = isMobileLayoutRecommended ? 15 : 24;

    return (
        <Row grow className={cn(s.floatingAreaHeader, { [s.floatingAreaHeaderVisible]: visible })}>

            { isDesktopLayoutRecommended &&
                <Column width={176} padding={{ left: logoSidePadding }} justification={EColumnJustification.CENTER}>
                    {logo}
                </Column>
            }

            <Column grow className={cn(s.searchFieldContainer, { [s.searchFieldContainerMobileLayout]: isMobileLayoutRecommended })}>
                <SearchField
                    tag={<IconMagnifierLine/>}
                    placeholder={'Найти алерты ...'}
                    defaultValue={searchQuery}
                    actions={[
                        {
                            icon: <IconCloseLine/>,
                            name: SearchTextFieldAction.RESET,
                            keyboardKeys: ['Escape'],
                            hidden: !searchQuery,
                        },
                        {
                            icon: <IconArrowRightLine/>,
                            name: SearchTextFieldAction.ENTER,
                            keyboardKeys: ['Enter', 'NumpadEnter'],
                            hidden: !searchQuery,
                        },
                    ]}
                    methodsRef={searchInputMethodsRef}
                    onAction={onSearchTextFieldAction}
                    onChanged={setEnteredSearchQuery}
                />
            </Column>

            {!!currentUser && !isMobileLayoutRecommended &&
                <Column padding={{ left: 20 }}>
                    <Button
                        caption={'Создать'}
                        className={s.headerButton}
                        color={EComponentColor.WHITE}
                        icon={<IconStar4Line/>}
                        onClick={goToStickerCreation}
                    />
                </Column>
            }

            <Column grow/>

            { currentUser && !isMobileLayoutRecommended &&
                <Row columnGap={20}>
                    <Column>
                        <Button
                            circle
                            className={s.headerButton}
                            color={EComponentColor.WHITE}
                            icon={<IconBellLine/>}
                            onClick={openEventsTab}
                        />
                    </Column>

                    <Column>
                        { balanceLoaded
                            ? welcomeBonusEarned || !streamer?.channel.welcomeBonusEnabled || !system.asBool('bonuses.enableWelcomeBonus')
                                ? <BalanceButton noShadow className={s.headerButton} {...props} />
                                : <EarnBonusButton noShadow onClick={onEarnWelcomeBonusClick}/>
                            : null
                        }
                    </Column>

                    <Column margin={{ right: logoSidePadding }} className={s.profileMenuContainer}>
                        <Avatar
                            source={currentUser?.avatar}
                            isUserAvatar={true}
                            rounded
                            ref={avatarRef}
                            onClick={() => setIsMenuVisible(!isMenuVisible)}
                        />
                        { isMenuVisible && currentUser &&
                            <ProfileMenu className={s.profileMenu} currentUser={currentUser} onLogout={logout}/>
                        }
                    </Column>
                </Row>
            }
        </Row>
    );
};
