import { ERowJustification, Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React from 'react';

import { useNavigator } from '@hooks/app/navigator';
import { ISticker, ITag } from '@typings';

import s from './Bottom.scss';
import { EStickerPopupControlsMode } from '../../enums';
import { IStickerPopupDataExtended } from '../../types';
import { Flags, TagsList } from '../components';

interface IBottomProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
    closeModal(): void;
}

export const Bottom = (props: IBottomProps) => {
    const {
        sticker,
        controlsMode,
        closeModal,
        streamer: { streamer },
        onClose = noop,
    } = props;
    const {
        goToSearch,
        gotoStreamerPageSearchResult,
    } = useNavigator();

    const handleTagClick = (tag: ITag) => {
        if (controlsMode === EStickerPopupControlsMode.SEND) {
            gotoStreamerPageSearchResult(streamer?.channel.link, tag.name);
        } else {
            goToSearch(tag.name);
        }

        closeModal();
        onClose();
    };

    return (
        <div className={cn(
            s.root,
            {
                [s.streamer]: controlsMode === EStickerPopupControlsMode.STREAMER,
                [s.viewer]: controlsMode === EStickerPopupControlsMode.VIEWER,
                [s.send]: controlsMode === EStickerPopupControlsMode.SEND,
            },
        )}>
            <Row
                className={s.bottomLine}
                justification={ERowJustification.SPACE_BETWEEN}
            >
                <div className={s.bottomLineLeft}>
                    {sticker.tags && (
                        <TagsList
                            tags={sticker.tags}
                            onTagClick={handleTagClick}
                        />
                    )}
                </div>
                <div className={s.bottomLineRigth}>
                    <Flags sticker={sticker}/>
                </div>
            </Row>
        </div>
    );
};
