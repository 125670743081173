import { ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { MobilePage, DesktopPage } from '@views';

import { Header, LeftSideMenu } from './components';


interface IPersonalAreaPage extends TApp {
    noRightSidebar?: boolean;
}

export const PersonalAreaPage = (props: React.PropsWithChildren<IPersonalAreaPage>) => {
    const { forceUpdateTheme } = useContext(ThemeContext);

    const {
        children,
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        noRightSidebar,
    } = props;

    useLayoutEffect(() => {
        forceUpdateTheme();
    }, []);

    const header = <Header {...props}/>;
    const footer = <Footer {...props}/>;
    const leftMenu = <LeftSideMenu {...props}/>;

    return (
        isMobileLayoutRecommended
            ? <MobilePage header={header}>
                {children}
            </MobilePage>
            : <DesktopPage header={header} footer={footer} leftSidebar={leftMenu} hideRightSidebar={noRightSidebar} stickyHeader>
                {children}
            </DesktopPage>
    );
};
