import { getFromLocalStorage, setToLocalStorage } from '@hyperclap/utils';
import { MutableRefObject, useEffect, useState } from 'react';

import { ISticker } from '@typings';

interface IContentParams {
    sticker: ISticker;
    videoRef: MutableRefObject<HTMLVideoElement | undefined>;
}

const LOCAL_STORAGE_PREFIX = 'stickerPopupContent';
const LOCAL_STORAGE_LOOP_KEY = `${LOCAL_STORAGE_PREFIX}Loop`;
const LOCAL_STORAGE_WITH_SOUND_KEY = `${LOCAL_STORAGE_PREFIX}WithSound`;

export const useContent = (params: IContentParams) => {
    const { sticker, videoRef } = params;
    const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);
    const loopFromLocalStorage = getFromLocalStorage(LOCAL_STORAGE_LOOP_KEY);
    const withSoundFromLocalStorage = getFromLocalStorage(LOCAL_STORAGE_WITH_SOUND_KEY);
    const defaultLoopState = loopFromLocalStorage === '1' || loopFromLocalStorage === null;
    const defaultWithSoundState = withSoundFromLocalStorage === '1' || withSoundFromLocalStorage === null;
    const [loop, setLoop] = useState(defaultLoopState);
    const [withSound, setWithSound] = useState(defaultWithSoundState);

    const onLoopToggle = () => {
        const value = !loop;

        setLoop(value);
        setToLocalStorage(LOCAL_STORAGE_LOOP_KEY, value ? '1' : '0');

        if (value && videoRef?.current && videoRef.current.ended) {
            videoRef.current.play();
        }
    };

    const onWithSoundToggle = () => {
        const value = !withSound;

        setWithSound(value);
        setToLocalStorage(LOCAL_STORAGE_WITH_SOUND_KEY, value ? '1' : '0');
    };

    useEffect(() => {
        if (sticker.stickerPreview) {
            const img = new Image();

            img.onload = () => setIsPreviewLoaded(true);
            img.src = sticker.stickerPreview;
        }
    }, [sticker?.stickerPreview]);

    return {
        isPreviewLoaded,
        loop,
        withSound,
        onLoopToggle,
        onWithSoundToggle,
    };
};
