import {
    Button,
    Column,
    Row,
    SearchField,
    useClickOutside,
    EColumnJustification,
    ITextFieldAction,
    ITextFieldMethods, ETheme, ThemeContext,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { IconArrowRightLine, IconBellLine, IconCloseLine, IconMagnifierLine, IconStar4Line } from '@assets/images/svg';
import { STICKER_SEARCH_QUERY_NAME } from '@common';
import { Avatar } from '@components';
import { TApp, useInvites } from '@hooks';
import { Logo, LogoKind } from '@memealerts/views';
import { ProfileMenu } from '@views';

import s from './Header.scss';


enum SearchTextFieldAction {
    ENTER = 'enter',
    RESET = 'reset',
}

interface IHeaderProps extends TApp {
    maxWidth?: number;
}

export const Header = (props: IHeaderProps) => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const {
        currentUser: {
            currentUser,
            logout,
        },
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        events: {
            hasUnreadEvents,
        },
        navigation: {
            goToDashboard,
            goToStickersCatalogue,
            goBack,
            goToChannelSettings,
            goToStickerCreation,
            goToSearch,
        },
        personalArea: {
            search: {
                searchQuery,
                changeSearchQuery,
            },
        },
        translation: {
            t,
        },
    } = props;

    const {
        invitesEnabled,
        inviteActivated,
    } = useInvites();

    const { theme } = useContext(ThemeContext);

    const avatarRef = useRef<HTMLDivElement>(null);
    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    useClickOutside({
        controlledElementRef: avatarRef,
        handler: () => setIsMenuVisible(false),
    });

    const [enteredSearchQuery, setEnteredSearchQuery] = useState(query || '');
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isStreamer, setIsStreamer] = useState<boolean>();

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        switch (action.name) {
            case SearchTextFieldAction.RESET:
                changeSearchQuery('');
                goBack();

                return true;
            case SearchTextFieldAction.ENTER:
                changeSearchQuery(enteredSearchQuery);
                goToSearch(enteredSearchQuery);
                break;
        }
    };

    const searchFieldActions = [
        {
            icon: <IconCloseLine/>,
            name: SearchTextFieldAction.RESET,
            keyboardKeys: ['Escape'],
            hidden: !searchQuery,
        },
        {
            icon: <IconArrowRightLine/>,
            name: SearchTextFieldAction.ENTER,
            keyboardKeys: ['Enter', 'NumpadEnter'],
            hidden: !enteredSearchQuery,
        },
    ];

    const logoLeftPadding = isMobileLayoutRecommended ? 15 : 24;

    useEffect(() => {
        setIsStreamer(invitesEnabled ? inviteActivated : true);
    }, [invitesEnabled, inviteActivated]);

    useEffect(() => {
        if (query !== searchQuery) {
            changeSearchQuery(query);
        }

        setEnteredSearchQuery(query);
    }, [query]);

    return (
        <Row grow className={cn(s.personalAreaHeader, { [s.personalAreaHeaderMobileLayout]: isMobileLayoutRecommended })}>
            <Column width={176} padding={{ left: logoLeftPadding }} justification={EColumnJustification.CENTER}>
                <Logo
                    width={96}
                    kind={theme === ETheme.LIGHT ? LogoKind.LIGHT : LogoKind.DARK}
                    onClick={isStreamer ? goToDashboard : goToStickersCatalogue}
                />
            </Column>
            { !isMobileLayoutRecommended
                ? <>
                    <Column className={s.personalAreaHeaderSearch} padding={{ right: 20 }}>
                        <SearchField
                            tag={<IconMagnifierLine/>}
                            placeholder={t('personal-area.header.find-stickers')}
                            defaultValue={searchQuery}
                            actions={searchFieldActions}
                            methodsRef={searchInputMethodsRef}
                            onAction={onSearchTextFieldAction}
                            onChanged={setEnteredSearchQuery}
                        />
                    </Column>
                    <Column>
                        <Button
                            icon={<IconStar4Line/>}
                            caption={t('common.create')}
                            onClick={goToStickerCreation}
                        />
                    </Column>
                    <Column grow/>
                    <Column padding={{ right: 20 }}>
                        <Link
                            className={cn(
                                s.personalAreaHeaderEventsButton,
                                { [s.personalAreaHeaderEventsButtonUnread]: hasUnreadEvents },
                            )}
                            to={'/all-events'} target='_blank'
                        >
                            <IconBellLine />
                        </Link>
                    </Column>
                    <Column padding={{ right: 20 }}>
                        <Button
                            caption={t('common.streamer-area')}
                            onClick={goToChannelSettings}
                        />
                    </Column>
                </>
                : <Column grow/>
            }
            <Column
                className={cn(
                    s.personalAreaHeaderAvatarContainer,
                    { [s.personalAreaHeaderAvatarContainerMobileLayout]: isMobileLayoutRecommended },
                )}
                ref={avatarRef}
            >
                <Avatar source={currentUser?.avatar} isUserAvatar rounded onClick={() => setIsMenuVisible(!isMenuVisible)}/>
                { isMenuVisible && currentUser &&
                    <ProfileMenu className={s.profileMenu} currentUser={currentUser} onLogout={logout}/>
                }
            </Column>
        </Row>
    );
};
