import {
    Button,
    EComponentColor,
    EComponentSize,
    EHorizontalRelativePosition,
    ITextFieldMethods,
    Row,
    Select,
    SelectOptionValueType,
    Switch,
    TextField,
    useLogger,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import {
    IconCheckCircleSolid,
    IconCloseLine,
    IconCopyrightLine,
    IconEtmaASignSolid,
    IconEtmaESignSolid,
    IconEtmaMSignSolid,
    IconEtmaTSignSolid,
    IconHeartSolid,
    IconSendMessageLine,
    IconShitSolid,
    IconTwitchUnsafeLine,
} from '@assets/images/svg';
import { DEFAULT_SEND_DELAY, DEFAULT_STICKER_PRICE, MEME_PARTY_DEFAULT_SEND_DELAY } from '@common';
import { Avatar } from '@components';
import { TApp, useCurrentUser } from '@hooks';
import { EtmaRating, ISticker, StickerSendTopic } from '@typings';
import { AuthButtons, BuyCurrencyPopup } from '@views';

import { SendButton, SendButtonColor } from './components';
import s from './SendPopup.scss';

const MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.memeParty.defaultSendDelay';
const DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.stickers.defaultSendDelay';
const STICKERS_MESSAGES_ENABLED_SETTING_NAME = 'channel.stickers.enableMessages';

interface StickerTopicProvider {
    updateStickerCustomSettings: (a: ISticker, deleteLocallyLoaded?: boolean) => void;
    updateStickerFavoriteState: (a: ISticker, deleteLocallyLoaded?: boolean) => Promise<ISticker>;
    updateStickerInChannelState: (a: ISticker, deleteLocallyLoaded?: boolean) => Promise<ISticker>;
}

interface SendPopupProps extends TApp {
    closeModal(): void;
    onClose(): void;
}

export const SendPopup = (props: SendPopupProps) => {
    const logger = useLogger({ target: SendPopup.name, showTimestamp: true });
    const {
        balance: {
            balance,
        },
        deviceInfo: {
            isAppleMobileDevice,
            isMobileLayoutRecommended,
        },
        navigation: {
            gotoStreamerPageSearchResult,
        },
        settings: {
            system,
        },
        stickers: {
            controllableVideoPlayerRef,
            memePartySessionState,
            memePartyRemainder,
            resetStickerToSend,
            sendSticker,
            sendMemeCannonSticker,
            selectedSticker,
            topic,
        },
        streamer: {
            streamer,
        },
        streamerArea: {
            stickers: {
                search,
                catalogue,
                channel,
                lastSent,
                mine,
                popular,
                favorites,
                thematicSet,
            },
        },
        closeModal,
        onClose,
    } = props;

    const {
        currentUser,
        refetchCurrentUser,
    } = useCurrentUser();

    const { showModal } = useModals();

    const [stickerInstance, setStickerInstance] = useState<ISticker>();
    const [isSoundOnlySendingEnabled, setIsSoundOnlySendingEnabled] = useState(false);
    const [isSendStickerClicked, setIsSendStickerClicked] = useState(false);
    const [isSendFullscreenStickerClicked, setIsFullscreenSendStickerClicked] = useState(false);
    const [isSendMemeCannonStickerClicked, setIsSendMemeCannonStickerClicked] = useState(false);
    const [isToFavoritesClicked, setIsToFavoritesClicked] = useState(false);
    const [isRegistrationRequest, setRegistrationRequest] = useState(false);
    const [stickerTopicProvider, setStickerTopicProvider] = useState<StickerTopicProvider>();
    const [memeCannonStickersCount, setMemeCountStickersCount] = useState(5);
    const [message, setMessage] = useState('');
    const [sendDelay, setSendDelay] = useState(system.asInt(MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME) ?? MEME_PARTY_DEFAULT_SEND_DELAY);
    const [sendButtonCaption, setSendButtonCaption] = useState('Отправить');

    const popupRef = useRef<HTMLDivElement>(null);
    const stickerContainerRef = useRef<HTMLDivElement>(null);
    const actionsContainerRef = useRef<HTMLDivElement>(null);
    const authContainerRef = useRef<HTMLDivElement>(null);
    const stickerWrapperRef = useRef<HTMLDivElement>(null);
    const messageInputMethodsRef = useRef<ITextFieldMethods>(null);

    const isStreamer = !!currentUser?.channel?.donationAlertsUsername;
    const isInFavorites = stickerInstance?.isFavorite;
    const isInChannel = stickerInstance?.isAddedToChannel;
    const isDenied = stickerInstance?.customSettings?.disableSticker;

    const stickerPrice = stickerInstance?.customSettings?.customPrice || streamer?.channel.catalogueStickerPrice || DEFAULT_STICKER_PRICE;
    const fullscreenStickerPrice = streamer?.channel.fullscreenStickersPrice || DEFAULT_STICKER_PRICE;
    const memeCannonStickerPrice = streamer?.channel.memeCannonPrice || DEFAULT_STICKER_PRICE;

    const onCloseClick = () => {
        controllableVideoPlayerRef.current?.pause();
        resetStickerToSend();
        closeModal();
        onClose();
    };

    const onSwitchDenied = () => {
        logger.info(stickerInstance);

        if (stickerInstance) {
            const updatedSticker: ISticker = {
                ...stickerInstance,
                customSettings: {
                    ...stickerInstance.customSettings,
                    disableSticker: !(stickerInstance.customSettings?.disableSticker ?? false),
                },
            };
            setStickerInstance(updatedSticker);
            stickerTopicProvider?.updateStickerCustomSettings(updatedSticker);
        }
    };

    const onSwitchFavorite = async () => {
        if (stickerInstance) {
            if (!currentUser) {
                setIsToFavoritesClicked(true);
                setRegistrationRequest(true);
            } else {
                const updatedSticker: ISticker = {
                    ...stickerInstance,
                    isFavorite: !stickerInstance.isFavorite,
                };

                const res = await stickerTopicProvider?.updateStickerFavoriteState(updatedSticker);

                if (res) {
                    updatedSticker.likedCount = res.likedCount;
                }

                setStickerInstance(updatedSticker);
            }
        }
    };

    const onSwitchInChannel = () => {
        if (stickerInstance) {
            const updatedSticker: ISticker = {
                ...stickerInstance,
                isAddedToChannel: !stickerInstance.isAddedToChannel,
            };

            setStickerInstance(updatedSticker);
            stickerTopicProvider?.updateStickerInChannelState(updatedSticker);
        }
    };

    const showBuyCurrencyPopup = () => {
        if (streamer && currentUser) {
            showModal({
                content: (
                    <BuyCurrencyPopup
                        streamer={streamer}
                        currentUser={currentUser}
                        isMobileLayoutRecommended={isMobileLayoutRecommended}
                    />
                ),
                options: {
                    bodyClassName: cn(
                        s.buyCurrencyPopupBody,
                        {
                            [s.buyCurrencyPopupBodyMobileLayout]: isMobileLayoutRecommended,
                        },
                    ),
                    containerClassName: cn(
                        s.buyCurrencyPopupContainer,
                        {
                            [s.buyCurrencyPopupContainerMobileLayout]: isMobileLayoutRecommended,
                            [s.buyCurrencyPopupContainerMobileLayoutApple]: isAppleMobileDevice,
                        },
                    ),
                },
            });
        }
    };

    const onSendStickerClick = async () => {
        if (streamer && stickerInstance) {
            setIsSendStickerClicked(true);

            if (currentUser) {
                if (balance >= stickerPrice || (memePartySessionState?.enabled && memePartySessionState?.active)) {
                    try {
                        const result = await sendSticker({
                            toChannel: streamer.id,
                            stickerId: stickerInstance.id,
                            isSoundOnly: isSoundOnlySendingEnabled,
                            topic: topic,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                        setMessage('');
                        setIsSoundOnlySendingEnabled(false);
                        messageInputMethodsRef.current?.reset();

                        return result;
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                setRegistrationRequest(true);
            }
        }

        return false;
    };

    const onSendFullscreenStickerClick = async () => {
        if (streamer && stickerInstance) {
            setIsFullscreenSendStickerClicked(true);

            if (currentUser) {
                if (balance >= fullscreenStickerPrice) {
                    try {
                        const result = await sendSticker({
                            toChannel: streamer.id,
                            fullscreen: true,
                            isSoundOnly: isSoundOnlySendingEnabled,
                            stickerId: stickerInstance.id,
                            topic: topic,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                        setMessage('');
                        setIsSoundOnlySendingEnabled(false);
                        messageInputMethodsRef.current?.reset();

                        return result;
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                setRegistrationRequest(true);
            }
        }

        return false;
    };

    const onSendMemeCannonStickerClick = async () => {
        if (streamer && stickerInstance) {
            setIsSendMemeCannonStickerClicked(true);

            if (currentUser) {
                if (balance >= memeCannonStickerPrice * memeCannonStickersCount) {
                    try {
                        const result = await sendMemeCannonSticker({
                            toChannel: streamer.id,
                            stickerId: stickerInstance.id,
                            isSoundOnly: isSoundOnlySendingEnabled,
                            topic: topic,
                            count: memeCannonStickersCount,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                        setMessage('');
                        setIsSoundOnlySendingEnabled(false);
                        messageInputMethodsRef.current?.reset();

                        return result;
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                setRegistrationRequest(true);
            }
        }

        return false;
    };

    const onSuccessRegistration = async () => {
        refetchCurrentUser();
    };

    const onCannonStickersChanged = (value?: SelectOptionValueType) => {
        setMemeCountStickersCount(Number(value));
    };

    const recalcSize = () => {
        console.log('=>');
        const availableHeight = window.innerHeight;
        const verticalMargin = 60;

        const maxPopupHeight = availableHeight - verticalMargin * 2;

        if (
            controllableVideoPlayerRef.current &&
            popupRef.current &&
            stickerContainerRef.current &&
            (actionsContainerRef.current || authContainerRef.current) &&
            stickerWrapperRef.current
        ) {
            popupRef.current.style.height = maxPopupHeight + 'px';
            popupRef.current.style.maxHeight = maxPopupHeight + 'px';

            const bottomBlockHeight = actionsContainerRef.current?.clientHeight ?? authContainerRef.current?.clientHeight ?? 0;

            const stickerContainerHeight = maxPopupHeight - bottomBlockHeight;
            const stickerContainerVerticalMargin = 24;
            // const stickerContainerNameRowHeight = 42;
            // const stickerContainerCreatorRowHeight = 48;

            stickerContainerRef.current.style.height = stickerContainerHeight + 'px';

            stickerWrapperRef.current.style.maxHeight = (
                stickerContainerHeight -
                stickerContainerVerticalMargin * 2 // -
                // stickerContainerNameRowHeight -
                // stickerContainerCreatorRowHeight
            ) + 'px';

            const stickerWrapperAspectRatio = stickerWrapperRef.current.clientWidth / stickerWrapperRef.current.clientHeight;

            if (
                selectedSticker &&
                selectedSticker.videoData?.aspectRatio &&
                stickerWrapperAspectRatio <= selectedSticker.videoData?.aspectRatio
            ) {
                controllableVideoPlayerRef.current.style.width = stickerWrapperRef.current.clientWidth + 'px';
                controllableVideoPlayerRef.current.style.height = 'auto';
                popupRef.current.style.height = 'auto';
                stickerContainerRef.current.style.height = 'auto';
            } else {
                controllableVideoPlayerRef.current.style.width = 'auto';
                controllableVideoPlayerRef.current.style.height = stickerWrapperRef.current.clientHeight + 'px';
            }
        }
    };

    const onTagClick = (tagName: string) => {
        resetStickerToSend();
        gotoStreamerPageSearchResult(streamer?.channel.link, tagName);
    };

    const viewerFavoritesIcon = <div className={s.viewerFavorites}>
        <div className={s.viewerFavoritesIcon}>{<IconHeartSolid/>}</div>
        <div className={s.viewerFavoritesValue}>{stickerInstance?.likedCount ? stickerInstance?.likedCount?.toString() : ''}</div>
    </div>;

    useEffect(() => {
        if (isSendStickerClicked || isSendFullscreenStickerClicked || isSendMemeCannonStickerClicked || isToFavoritesClicked) {
            setRegistrationRequest(!currentUser);
        }
    }, [currentUser]);

    useEffect(() => {
        const sendDelay = memePartySessionState?.active
            ? streamer?.channel?.memePartySendDelay ??
                system.asInt(MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME) ??
                MEME_PARTY_DEFAULT_SEND_DELAY
            : streamer?.channel.sendDelay ??
                system.asInt(DEFAULT_SEND_DELAY_SETTING_NAME) ??
                DEFAULT_SEND_DELAY;
        setSendDelay(sendDelay);
    }, [streamer, memePartySessionState]);

    useEffect(() => {
        switch (topic) {
            case StickerSendTopic.CHANNEL:
                setStickerTopicProvider(channel);
                break;
            case StickerSendTopic.FAVORITES:
                setStickerTopicProvider(favorites);
                break;
            case StickerSendTopic.LAST:
                setStickerTopicProvider(lastSent);
                break;
            case StickerSendTopic.MY_CREATED:
                setStickerTopicProvider(mine);
                break;
            case StickerSendTopic.POPULAR:
                setStickerTopicProvider(popular);
                break;
            case StickerSendTopic.SEARCH:
                setStickerTopicProvider(search);
                break;
            case StickerSendTopic.TOP:
                setStickerTopicProvider(catalogue);
                break;
            case StickerSendTopic.THEMATIC_SET:
                setStickerTopicProvider(thematicSet);
                break;
            default:
                setStickerTopicProvider(undefined);
                break;
        }
    }, [topic]);

    useEffect(() => {
        setStickerInstance(selectedSticker);

        if (selectedSticker) {
            if (isMobileLayoutRecommended) {
                recalcSize();
            } else {
                if (controllableVideoPlayerRef.current) {
                    if ((selectedSticker.videoData?.aspectRatio ?? 1) >= 0.67111) {/* 302 / 450 - width / height */
                        controllableVideoPlayerRef.current.style.width = '100%';
                        controllableVideoPlayerRef.current.style.height = 'auto';
                    } else {
                        controllableVideoPlayerRef.current.style.width = 'auto';
                        controllableVideoPlayerRef.current.style.height = '100%';
                    }
                }
            }
        } else {
            setRegistrationRequest(false);
            setIsSoundOnlySendingEnabled(false);
        }
    }, [selectedSticker]);

    useEffect(() => {
        if (isMobileLayoutRecommended && !!selectedSticker) {
            logger.debug('Тест');
            recalcSize();
        }
    }, [isRegistrationRequest]);

    useEffect(() => {
        logger.debug(memePartyRemainder);

        const seconds = Math.round(memePartyRemainder / 1000);
        const sec = seconds % 60;
        const min = Math.round((seconds - sec) / 60);

        logger.debug(`Seconds: ${seconds}, Sec part: ${sec}, Min part: ${min}`);

        // If remainder more than 0.499 sec - render time else render usual caption
        setSendButtonCaption(memePartyRemainder > 499 ? `Осталось ${
            min.toFixed(0).padStart(2, '0')
        }:${
            sec.toFixed(0).padStart(2, '0')
        }` : 'Отправить');
    }, [memePartyRemainder]);

    return (
        <div
            ref={popupRef}
            className={cn(
                s.sendPopup,
                {
                    [s.sendPopupMobileLayout]: isMobileLayoutRecommended,
                },
            )}
            onClick={(e) => e.stopPropagation()}
        >

            <div ref={stickerContainerRef} className={s.stickerContainer}>
                {!isMobileLayoutRecommended &&
                    <div className={cn(
                        s.stickerContainerName,
                        {
                            [s.stickerContainerNameMobileLayout]: isMobileLayoutRecommended,
                        },
                    )}>
                        {stickerInstance?.name}
                    </div>
                }
                <div ref={stickerWrapperRef} className={s.stickerContainerStickerWrapper}>
                    <video
                        ref={controllableVideoPlayerRef}
                        className={s.sticker}
                        src={isAppleMobileDevice ? stickerInstance?.fallbackUrl : stickerInstance?.stickerUrl}
                        autoPlay
                        playsInline
                        muted={!stickerInstance}
                    />
                </div>
                {!isMobileLayoutRecommended && selectedSticker && selectedSticker.tags && selectedSticker.tags.length > 0 &&
                    <div className={s.tag}>
                        {selectedSticker.tags.slice(0, 5).map((tag) => (
                            <div className={s.tagItem} key={tag.name} onClick={() => onTagClick(tag.name)}>
                                <div className={s.tagItemText}>#{tag.name}</div>
                            </div>
                        ))}
                    </div>
                }
                { !isMobileLayoutRecommended &&
                    <>
                        <div className={s.expander}/>

                        <div className={s.stickerContainerFooter}>
                            <div className={s.stickerContainerFooterAvatar}>
                                <Avatar rounded size={EComponentSize.SMALL}/>
                            </div>
                            <div className={s.stickerContainerFooterCreator}>
                                {stickerInstance?.creatorName}
                            </div>
                            <div className={s.expander}/>
                            <div className={s.stickerContainerFooterSent}>
                                <div className={s.stickerContainerFooterSentIcon}><IconSendMessageLine/></div>
                                <div className={s.stickerContainerFooterSentCount}>{stickerInstance?.usageCount}</div>
                            </div>
                        </div>
                    </>
                }
            </div>

            { !isRegistrationRequest &&
            <div ref={actionsContainerRef} className={s.actionsContainer}>
                { isStreamer && !isMobileLayoutRecommended &&
                    <div className={s.actionsContainerStreamerBlock}>
                        <Row columnGap={7} className={s.buttonsRow}>
                            <Button
                                className={cn(
                                    s.toFavoritesButton,
                                    s.toFavoritesButtonSmall,
                                    {
                                        [s.coloredRed]: isInFavorites,
                                    },
                                )}
                                height={50}
                                icon={<IconHeartSolid/>}
                                caption={stickerInstance?.likedCount ? stickerInstance?.likedCount?.toString() : ''}
                                onClick={onSwitchFavorite}
                            />
                            <div className={s.expander} />
                            <Button
                                className={cn(
                                    s.toChannelButton,
                                    {
                                        [s.coloredGreen]: isInChannel,
                                    },
                                )}
                                height={50}
                                icon={<IconCheckCircleSolid/>}
                                onClick={onSwitchInChannel}
                            />
                            <Button
                                className={cn(
                                    s.denyButton,
                                    {
                                        [s.coloredBrown]: isDenied,
                                    },
                                )}
                                height={50}
                                icon={<IconShitSolid/>}
                                onClick={onSwitchDenied}
                            />
                        </Row>
                    </div>
                }

                { system.asBool(STICKERS_MESSAGES_ENABLED_SETTING_NAME) &&
                    streamer?.channel.isPopupNickNameEnabled &&
                    streamer?.channel.isStickersMessagesEnabled &&
                    !streamer?.channel.disableStickers &&
                    <Row margin={{ bottom: 10 }} className={s.inputRow}>
                        <TextField
                            placeholder={'Ваше сообщение'}
                            className={s.messageInput}
                            wrapperClassName={s.messageInputWrapper}
                            maxLength={50}
                            methodsRef={messageInputMethodsRef}
                            onChanged={(val) => setMessage(val)}
                        />
                    </Row>
                }

                { !streamer?.channel.disableStickers
                    ? <>
                        <Row>
                            <SendButton
                                fullWidth
                                caption={sendButtonCaption}
                                className={s.sendButtonMargin}
                                color={SendButtonColor.ORANGE}
                                currencyIcon={streamer?.channel?.currencyImageUrl || ImageDefaultCurrency}
                                price={memePartySessionState?.active ? 0 : stickerPrice}
                                sendDelay={sendDelay}
                                onClick={onSendStickerClick}
                            />
                        </Row>

                        { streamer?.channel.fullscreenStickersEnabled &&
                            <Row>
                                <SendButton
                                    fullWidth
                                    caption='Full Screen'
                                    className={s.sendButtonMargin}
                                    color={SendButtonColor.BLUE}
                                    currencyIcon={streamer?.channel?.currencyImageUrl || ImageDefaultCurrency}
                                    price={streamer?.channel?.fullscreenStickersPrice ?? 100}
                                    sendDelay={sendDelay}
                                    onClick={onSendFullscreenStickerClick}
                                />
                            </Row>
                        }

                        { streamer?.channel.memeCannonEnabled && system.asBool('channel.enableMemeCannon') &&
                            <>
                                <Row columnGap={7}>
                                    <SendButton
                                        fullWidth
                                        caption='Мем-пушка'
                                        color={SendButtonColor.ROSE}
                                        currencyIcon={streamer?.channel?.currencyImageUrl || ImageDefaultCurrency}
                                        price={(streamer?.channel?.memeCannonPrice ?? 10) * memeCannonStickersCount}
                                        sendDelay={sendDelay}
                                        onClick={onSendMemeCannonStickerClick}
                                    />
                                    <Select
                                        width={80}
                                        className={s.memeCannonSelector}
                                        options={[
                                            { order: 0, value: 5, caption: '5', selected: memeCannonStickersCount === 5 },
                                            { order: 1, value: 10, caption: '10', selected: memeCannonStickersCount === 10 },
                                            { order: 2, value: 15, caption: '15', selected: memeCannonStickersCount === 15 },
                                            { order: 3, value: 25, caption: '25', selected: memeCannonStickersCount === 25 },
                                            { order: 4, value: 50, caption: '50', selected: memeCannonStickersCount === 50 },
                                            { order: 5, value: 100, caption: '100', selected: memeCannonStickersCount === 100 },
                                        ]}
                                        onValueChanged={onCannonStickersChanged}
                                    />
                                </Row>
                            </>
                        }

                        { streamer?.channel.isSoundOnlyStickersEnabled &&
                            <Row margin={{ top: 20 }}>
                                <Switch
                                    caption={'Только звук'}
                                    captionPosition={EHorizontalRelativePosition.RIGHT}
                                    color={EComponentColor.ACCENT}
                                    checked={isSoundOnlySendingEnabled}
                                    onChange={setIsSoundOnlySendingEnabled}
                                />
                            </Row>
                        }
                    </>
                    : <div className={s.stickersDisabled}>{'Стример временно выключил возможность отправки стикеров.'}</div>
                }

                {(!isStreamer || isMobileLayoutRecommended) &&
                    <div className={s.actionsContainerViewerBlock}>
                        <Row>
                            <Button
                                className={cn(
                                    s.toFavoritesButton,
                                    {
                                        [s.coloredRed]: isInFavorites,
                                    },
                                )}
                                icon={viewerFavoritesIcon}
                                iconClassName={s.toFavoritesButtonIcon}
                                caption={!isInFavorites ? 'В избранное' : 'Избранное'}
                                fullWidth
                                onClick={onSwitchFavorite}
                            />
                        </Row>
                    </div>
                }

                <div className={s.expander}/>
                { !isMobileLayoutRecommended && stickerInstance &&
                    <div className={s.actionsContainerFlagsBlock}>
                        { stickerInstance.hasCopyright && <div className={cn(s.flag, s.flagCopyright)}><IconCopyrightLine/></div> }
                        { stickerInstance.isNotTwitchSafe && <div className={cn(s.flag)}><IconTwitchUnsafeLine/></div> }
                        { stickerInstance.etmaRating === EtmaRating.E && <div className={cn(s.flag)}><IconEtmaESignSolid/></div> }
                        { stickerInstance.etmaRating === EtmaRating.T && <div className={cn(s.flag)}><IconEtmaTSignSolid/></div> }
                        { stickerInstance.etmaRating === EtmaRating.M && <div className={cn(s.flag)}><IconEtmaMSignSolid/></div> }
                        { stickerInstance.etmaRating === EtmaRating.A && <div className={cn(s.flag)}><IconEtmaASignSolid/></div> }
                    </div>
                }
            </div>
            }

            {isRegistrationRequest &&
                    <div
                        ref={authContainerRef}
                        className={cn(
                            s.auth,
                            {
                                [s.authMobileLayout]: isMobileLayoutRecommended,
                            },
                        )}
                    >
                        <div className={s.authTitle}>{'Войдите или зарегистрируйтесь'}</div>
                        <AuthButtons
                            className={s.authButtons}
                            returnUrl={location.pathname}
                            callback={onSuccessRegistration}
                        />
                    </div>
            }

            <div className={s.closeButton} onClick={onCloseClick}>
                <IconCloseLine/>
            </div>

        </div>
    );
};
