import React from 'react';

import { ITag } from '@typings';

import s from './TagItem.scss';

interface ITagItemProps {
    tag: ITag;
    onClick(tag: ITag): void;
}

export const TagItem = (props: ITagItemProps) => {
    const { tag, onClick } = props;

    const handleClick = () => {
        onClick(tag);
    };

    return (
        <div className={s.root} onClick={handleClick}>
            #{tag.name}
        </div>
    );
};
