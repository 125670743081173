import { useLogger } from '@hyperclap/ui';
import { useState } from 'react';

import { DEFAULT_SEND_DELAY, DEFAULT_STICKER_PRICE, MEME_PARTY_DEFAULT_SEND_DELAY } from '@common';
import { ISticker } from '@typings';

import { IStickerPopupDataExtended } from '../../../../../types';

interface IUseControlsSendParams extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
    showBuyCurrencyPopup(): void;
    showAuth(): void;
}

const MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.memeParty.defaultSendDelay';
const DEFAULT_SEND_DELAY_SETTING_NAME = 'channel.stickers.defaultSendDelay';
const STICKERS_MESSAGES_ENABLED_SETTING_NAME = 'channel.stickers.enableMessages';

export const useControlsSend = (params: IUseControlsSendParams) => {
    const {
        sticker,
        balance: { balance },
        currentUser: { currentUser },
        streamer: { streamer },
        stickers: {
            memePartySessionState,
            sendSticker,
            sendMemeCannonSticker,
            topic,
            addStickerToFavorites,
            removeStickerFromFavorites,
        },
        settings: {
            system,
        },
        showBuyCurrencyPopup,
        showAuth,
        updateSticker,
    } = params;
    const logger = useLogger({ target: 'useControlsSend', showTimestamp: true });
    const [message, setMessage] = useState('');
    const [memeCannonStickersCount, setMemeCountStickersCount] = useState(5);
    const [isSoundOnly, setIsSoundOnly] = useState(false);
    const stickerPrice = memePartySessionState?.active
        ? 0
        : (
            sticker?.customSettings?.customPrice ||
            streamer?.channel.catalogueStickerPrice ||
            DEFAULT_STICKER_PRICE
        );
    const fullscreenStickerPrice = streamer?.channel.fullscreenStickersPrice || DEFAULT_STICKER_PRICE;
    const memeCannonStickerPrice = streamer?.channel.memeCannonPrice || DEFAULT_STICKER_PRICE;
    const cooldownTime = memePartySessionState?.active
        ? streamer?.channel?.memePartySendDelay ??
            system.asInt(MEME_PARTY_DEFAULT_SEND_DELAY_SETTING_NAME) ??
            MEME_PARTY_DEFAULT_SEND_DELAY
        : streamer?.channel.sendDelay ??
            system.asInt(DEFAULT_SEND_DELAY_SETTING_NAME) ??
            DEFAULT_SEND_DELAY;
    const isMessageEnabled = system.asBool(STICKERS_MESSAGES_ENABLED_SETTING_NAME) &&
        streamer?.channel.isPopupNickNameEnabled &&
        streamer?.channel.isStickersMessagesEnabled &&
        !streamer?.channel.disableStickers;

    const onStickerSend = async () => {
        if (streamer && sticker) {
            if (currentUser) {
                if (balance >= stickerPrice || (memePartySessionState?.enabled && memePartySessionState?.active)) {
                    try {
                        return await sendSticker({
                            toChannel: streamer.id,
                            stickerId: sticker.id,
                            isSoundOnly,
                            topic,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                showAuth();
            }
        }
    };

    const onFullscreenSend = async () => {
        if (streamer && sticker) {
            if (currentUser) {
                if (balance >= fullscreenStickerPrice) {
                    try {
                        return await sendSticker({
                            toChannel: streamer.id,
                            fullscreen: true,
                            isSoundOnly,
                            stickerId: sticker.id,
                            topic,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                showAuth();
            }
        }
    };

    const onCannonSend = async () => {
        if (streamer && sticker) {
            if (currentUser) {
                if (balance >= memeCannonStickerPrice * memeCannonStickersCount) {
                    try {
                        return await sendMemeCannonSticker({
                            toChannel: streamer.id,
                            stickerId: sticker.id,
                            isSoundOnly,
                            topic,
                            count: memeCannonStickersCount,
                            name: currentUser.name,
                            isMemePartyActive: (memePartySessionState?.enabled && memePartySessionState?.active),
                            message,
                        }).unwrap();
                    } catch (error) {
                        logger.error(error);
                    }
                } else {
                    showBuyCurrencyPopup();
                }
            } else {
                showAuth();
            }
        }
    };

    const onFavoritesClick = async () => {
        let updatedSticker: ISticker;

        if (sticker.isFavorite) {
            updatedSticker = await removeStickerFromFavorites(sticker);
        } else {
            updatedSticker = await addStickerToFavorites(sticker);
        }

        updateSticker(updatedSticker);
    };

    const onMessageChange = (value: string) => {
        setMessage(value);
    };

    const onMemeCannonStickersCountChange = (value: number) => setMemeCountStickersCount(value);

    const onSoundOnlyChange = (value: boolean) => setIsSoundOnly(value);

    return {
        memeCannonStickersCount,
        isSoundOnly,
        isMessageEnabled,
        price: {
            sticker: stickerPrice,
            fullscreen: fullscreenStickerPrice,
            cannon: memeCannonStickerPrice * memeCannonStickersCount,
        },
        cooldownTime,
        onStickerSend,
        onFullscreenSend,
        onCannonSend,
        onMessageChange,
        onMemeCannonStickersCountChange,
        onFavoritesClick,
        onSoundOnlyChange,
    };
};
