import cn from 'classnames';
import React from 'react';

import s from './ControlItem.scss';

interface IControlItemProps {
    classNames?: {
        root?: string;
        icon?: string;
        title?: string;
    };
    title: string;
    icon: JSX.Element;
    onClick?(): void;
}

export const ControlItem = (props: React.PropsWithChildren<IControlItemProps>) => {
    const {
        classNames = {},
        title,
        icon,
        children,
        onClick,
    } = props;

    return (
        <div
            className={cn(s.root, classNames.root)}
            onClick={onClick}
        >
            <div className={cn(s.icon, classNames.icon)}>
                {icon}
            </div>
            <div className={cn(s.title, classNames.title)}>
                {title}
            </div>

            {children}
        </div>
    );
};
