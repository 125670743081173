import { Column, EColumnAlignment, EComponentSize, Icon, Row } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconDizzyLine } from '@assets/images/svg';
import { USER_BANNED_ID_NAME } from '@common';

import s from './Suspesion.scss';

export const Suspension = () => {
    const { t } = useTranslation();

    return (
        <Row padding={30}>
            <Column grow rowGap={20} alignment={EColumnAlignment.CENTER}>
                <Icon className={s.icon} icon={<IconDizzyLine/>} size={EComponentSize.LARGE}/>

                <Row className={s.text}>
                    {t('suspension.text')}{localStorage.getItem(USER_BANNED_ID_NAME)}
                </Row>
            </Column>
        </Row>
    );
};

